<template>
  <!-- <div v-if="report.interventionType == 'QUICK'">This is a FLaSH report</div> -->
  <!-- <div> -->

  <div class="d-flex">
    <div class="mr-4 width-280">
      <MapBlock
        ref="mapBlock"
        :reportUuid="reportUuid"
        :fileName="file"
        :x="report.markerX"
        :y="report.markerY"
      />
    </div>

    <ul class="el-upload-list el-upload-list--picture-card">
      <li
        tabindex="0"
        class="el-upload-list__item is-ready"
        v-if="srcPhoto1 != ''"
      >
        <div>
          <transition>
            <b-img
              :src="srcPhoto1"
              v-show="isLoad"
              alt=""
              class="row-image"
              @load="loaded"
            ></b-img>
          </transition>
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click.prevent="showPreview(srcPhoto1)"
            ><i class="el-icon-zoom-in"></i></span>
          </span>
          <div
            class="loading-container"
            v-show="!isLoad"
            v-loading="true"
          ></div>
        </div>
      </li>
      <li
        tabindex="0"
        class="el-upload-list__item is-ready"
        v-if="srcPhoto2 != ''"
      >
        <div>
          <transition>
            <b-img
              :src="srcPhoto2"
              v-show="isLoad"
              alt=""
              class="row-image"
              @load="loaded"
            ></b-img>
          </transition>
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click.prevent="showPreview(srcPhoto2)"
            ><i class="el-icon-zoom-in"></i></span>
          </span>
          <div
            class="loading-container"
            v-show="!isLoad"
            v-loading="true"
          ></div>
        </div>
      </li>
      <li
        tabindex="0"
        class="el-upload-list__item is-ready"
        v-if="srcPhoto3 != ''"
      >
        <div>
          <transition>
            <b-img
              :src="srcPhoto3"
              v-show="isLoad"
              alt=""
              class="row-image"
              @load="loaded"
            ></b-img>
          </transition>
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click.prevent="showPreview(srcPhoto3)"
            ><i class="el-icon-zoom-in"></i></span>
          </span>
          <div
            class="loading-container"
            v-show="!isLoad"
            v-loading="true"
          ></div>
        </div>
      </li>
    </ul>

    <History
      :reportUuid="report.uuid"
      class="ml-4 width-400"
    />

    <!-- <el-dialog
      :visible.sync="dialogVisible"
      style="z-index: 2002;"
    >
      <span slot="title">
        Photo report #{{ report.uuid }}
      </span>

      <b-img
        fluid
        class="photo-container"
        v-show="isLoad"
        :src="previewUrl"
        @load="loaded"
      ></b-img>

      <div
        class="loading-container"
        v-show="!isLoad"
        v-loading="true"
      ></div>

    </el-dialog> -->
    <!-- <div v-if="srcPhoto1 != ''">
      <transition>
        <img
          class="photo-container"
          v-show="isLoad"
          :src="srcPhoto1"
          @load="loaded"
        >

      </transition>

      <div
        class="loading-container"
        v-show="!isLoad"
        v-loading="true"
      ></div>
    </div>

    <div v-if="srcPhoto2 != ''">
      <transition>
        <img
          class="photo-container"
          v-show="isLoad"
          :src="srcPhoto1"
          @load="loaded"
        >
      </transition>
      <div
        class="loading-container"
        v-show="!isLoad"
        v-loading="true"
      ></div>
    </div>

    <div v-if="srcPhoto3 != ''">
      <transition>
        <img
          class="photo-container"
          v-show="isLoad"
          :src="srcPhoto1"
          @load="loaded"
        >
      </transition>
      <div
        class="loading-container"
        v-show="!isLoad"
        v-loading="true"
      ></div>
    </div> -->

  </div>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import MapBlock from './MapBlock.vue';
// import History from '../HistoryComponents/History.vue';

import History from '@/components/Explore/SharedExploreComponents/History.vue';

export default {
  name: 'details-row',
  props: ['report'],
  components: {
    MapBlock,
    History,
  },
  data() {
    return {
      projectRef: this.$store.getters.currentProject.ref,
      isLoad: false,
      srcPhoto1: '',
      srcPhoto2: '',
      srcPhoto3: '',
      srcMap: '',
      dialogVisible: false,
      previewUrl: '',
      file: this.report.presignedUrlDrawing,
      reportUuid: this.report.uuid,
    };
  },
  methods: {
    showPreview(url) {
      // this.dialogVisible = true;
      this.previewUrl = url;
      this.$bvModal.show('modal-view-photo');
      this.$parent.$refs.modalViewPhoto.previewUrl = url;
      this.$parent.$refs.modalViewPhoto.reportUuid = this.report.uuid;
    },
    loaded() {
      //わかりやすくするためsetTimeout処理入れてます。
      this.isLoad = true;
    },
    fetchPresignedUrl() {
      axiosResource
        .get(`/reports/presignedURL/${this.projectRef}/${this.report.uuid}`)
        .then((res) => {
          this.srcPhoto1 = res.data.presignedUrl1;
          this.srcPhoto2 = res.data.presignedUrl2;
          this.srcPhoto3 = res.data.presignedUrl3;
        })
        .catch((err) => {
          alert(err);
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
  mounted() {
    if (this.report.interventionType != 'QUICK') {
      this.fetchPresignedUrl();
    }
  },
};
</script>
<style >
.loading-container {
    width: 148px;
    height: 148px;
}
.el-loading-mask {
     background-color: #f6f9fc !important;
} 

.photo-container {
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    padding: 60px;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;
}


.el-upload-list__item {
    opacity: 1;
}
.el-upload-list--picture-card .el-upload-list__item-actions:hover {
    opacity: 1;
}

.el-upload-list--picture-card .el-upload-list__item-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0,0,0,.5);
    transition: opacity .3s;
}











.el-upload-list--picture-card {
    margin: 0;
    display: inline;
    vertical-align: top;
}
.el-upload-list {
    margin: 0;
    padding: 0;
    list-style: none;
}


.el-upload-list--picture-card .el-upload-list__item {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
}

.el-upload-list--picture-card .el-upload-list__item {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
}
.row-image {
  height: 146px;
  width: 146px;
  position: relative;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

</style>
