<template>
  <div>
    <div
      class="map-block"
      v-loading="loading"
    >
      <font-awesome-icon
        class="icon"
        icon="fa-solid fa-location-dot"
      />
      <div
        :id="'canvas-container-block' + reportUuid"
        class="canvas-container-block"
      >
        <canvas :id="'pdf-renderer-block' + reportUuid"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
pdfjsLib.GlobalWorkerOptions.workerSrc =
  '../../node_modules/pdfjs-dist/build/pdf.worker.js';

export default {
  name: 'map-block',
  props: ['reportUuid', 'fileName', 'x', 'y'],
  data() {
    return {
      loading: true,
      pdf: null,
      scale: 2,
      canvas: {
        width: null,
        height: null,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.lazyLoadDocument();
    });
  },
  methods: {
    lazyLoadDocument() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.getDocument();
              observer.disconnect();
            }
          });
        },
        {
          rootMargin: '0px 0px 200px 0px',
        }
      );

      observer.observe(this.$el);
    },
    async getDocument() {
      const url = this.fileName;
      try {
        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        this.pdf = pdf;
        this.render();
      } catch (error) {
        console.error('Error loading PDF:', error);
        this.loading = false;
      }
    },
    render() {
      this.loading = true;
      const pdf = this.pdf;
      pdf.getPage(1).then((page) => {
        const canvas = document.getElementById(
          'pdf-renderer-block' + this.reportUuid
        );
        const ctx = canvas.getContext('2d');
        const scale = this.scale;
        const viewport = page.getViewport({ scale });

        canvas.width = viewport.width;
        canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;

        const renderContext = {
          canvasContext: ctx,
          viewport,
        };

        page.render(renderContext).then(() => {
          this.scrollTo();
          this.loading = false;
        });
      });
    },
    scrollTo() {
      const canvasContainerBlock = document.getElementById(
        'canvas-container-block' + this.reportUuid
      );
      const scrollToX =
        (this.x / 100) * this.canvas.width -
        canvasContainerBlock.clientWidth / 2;
      const scrollToY =
        (this.y / 100) * this.canvas.height -
        canvasContainerBlock.clientHeight / 2;

      canvasContainerBlock.scrollTo(scrollToX, scrollToY, 'instant');
    },
  },
};
</script>

<style scoped lang="scss">
.canvas-container-block {
  width: 100% !important;
  height: 200px !important;
  overflow: hidden !important;
}
.map-block {
  width: 100%;
  height: 150px !important;
  row-gap: 8px;
  margin: 0 0 !important;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  transition: 0.3s ease all;
  overflow: hidden;
  z-index: 0;
  position: relative;

  .icon {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 15px);
    color: #ff453a;
    font-size: 40px;
    margin: auto;
  }
}
</style>
